import React from "react"
import { graphql } from "gatsby"
import { Layout, Seo, Hero } from "../components"
import { getImage } from "gatsby-plugin-image"

export default function NotFoundPage({ data }) {
  const banner = getImage(data.banner)
  return (
    <Layout>
      <Seo title="404 Niet Gevonden" />
      <Hero image={banner}>
        404 <br /> Niet gevonden
      </Hero>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    banner: file(name: { eq: "contact-audio" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 960)
      }
    }
  }
`
